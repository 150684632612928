import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import './productgriditem.css';
import './PromoForm.css';
import Form from './form';
import logo from './logo.svg';
import xsik from './strzalka.svg';
import aliexpressLogo from './aliexpress.png';
import aliexpressLogoMini from './aliexpressmini.png';
import amazonLogo from './amazon.png';
import amazonLogoMini from './amazonMini.png';
import temuLogo from './logotemu.png';
import temuLogoMini from './temu.png'; // Import the Temu logo
import copyImg from '../src/Copy-24.png';
import discountImg from '../src/discount.png'
import trophyImg from '../src/assets/trophy.png'
import homeImg from '../src/assets/home.png'
import toyImg from '../src/assets/toy-train.png'
import petsImg from '../src/assets/pets.png'
import ideaImg from '../src/assets/idea.png'
import seasonsImg from '../src/assets/season.png'
import giftImg from '../src/assets/giftbox.png'
import presentsImg from '../src/assets/presents.png'
import ig from './ig.png';
import yt from './yt.png';
import tt from './tt.png';
import fb from './fb.png';
import searchIcon from './searchIcon.png';


const companyLogos = {
  aliexpress: aliexpressLogo,
  amazon: amazonLogo,
  temu: temuLogo, // Add Temu to the companyLogos object
};

const getCompanyFromUrl = (url) => {
  if (!url) {
    return null;
  }
  if (url.includes('aliexpress')) {
    return 'aliexpress';
  } else if (url.includes('amazon')) {
    return 'amazon';
  } else if (url.includes('temu')) { // Add Temu to the getCompanyFromUrl function
    return 'temu';
  }
  else if (url.includes('amzn')) { // Add Temu to the getCompanyFromUrl function
    return 'amazon';
  }
  return null;
};

const PostWithBackground = ({ thumbnailSrc, videoSrc, onClick }) => {
  const handleThumbnailClick = () => {
    onClick(videoSrc, thumbnailSrc);
  };

  return (
    <img
      className="post-with-background product"
      src={thumbnailSrc}
      alt="Product Thumbnail"
      loading="lazy"
      onClick={handleThumbnailClick}
      //style={{ width: '100%', height: 'auto', cursor: 'pointer' }}
    />
  );
};

const FullScreenDiv = ({ onClose, links, showOrangeDiv, setShowOrangeDiv, videoSrc, thumbnailSrc }) => {
  const videoRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  useEffect(() => {
    // Add state to history when full-screen-div is opened
    window.history.pushState({ fullScreenDiv: true }, '');

    const handlePopState = (event) => {
      // Check if the state is related to full-screen-div
      if (event.state && event.state.fullScreenDiv) {
        onClose();
      }
    };

    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, [onClose]);

  const toggleVideoPlayPause = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsVideoPlaying(true);
    } else {
      videoRef.current.pause();
      setIsVideoPlaying(false);
    }
  };

  const handleBottomButtonClick = () => {
    setShowOrangeDiv(!showOrangeDiv);
    if (!showOrangeDiv) {
        window.history.pushState({ showOrangeDiv: true }, ''); // Dodaj stan do historii
    }
};


  return (
    <div className="full-screen-div-overlay">
      <div className="full-screen-div-content" style={{ backgroundImage: `url(${thumbnailSrc})` }}>
        <video
          ref={videoRef}
          src={videoSrc}
          loop
          onClick={toggleVideoPlayPause}
          onEnded={() => setIsVideoPlaying(false)}
          playsInline
        />

        <button className="bottom-button" onClick={handleBottomButtonClick}>
          <div className="bottom-button-content">
            <div className="bottom-button-expand-text">Pokaż Produkty</div>
            <img className="linkImg" src="link.png" alt="Opis obrazka" />
          </div>
        </button>

        {isVideoPlaying ? (
          <div className="play-pause-icon" onClick={toggleVideoPlayPause}></div>
        ) : (
          <div className="play-pause-icon" onClick={toggleVideoPlayPause}>
            &#9654;
          </div>
        )}
        {showOrangeDiv && (
          <div className="orange-div">
            <div className="linkiHeader">
              <div className="LinkiDoProduktow"></div>
              <div className="x" onClick={() => setShowOrangeDiv(false)}>
                <img className="xus" src={xsik} alt="Opis obrazka" />
              </div>
            </div>
            <div className="product-grid-item">
              {links.map((link, index) => (
                <ProductItem key={index} link={link} />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};


const ProductLinksList = ({ links }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const renderCompanyLogo = (url) => {
    const company = getCompanyFromUrl(url);
    if (company && companyLogos[company]) {
      return (
        <img
          src={companyLogos[company]}
          alt={company}
          className="company-logo"
        />
      );
    }
    return null;
  };

  return (
    <ul className="expanded-list">
      {links.map((link, index) => {
        const hasAdditionalLinks = link.additionalLinks && link.additionalLinks.length > 0;
        const isSingleLink = !hasAdditionalLinks && link.url;

        return (
          <li key={index} className="link-item">
            {isSingleLink ? (
              <a href={link.url} target="_blank" rel="noopener noreferrer" className="link-container">
                <div className="link-container">
                  {link.imageSrc && <img src={link.imageSrc} alt={link.text} className="product-thumbnail" />}
                  <div className="link-text">{link.text}</div>
                </div>
              </a>
            ) : (
              <div className="link-container" onClick={() => toggleExpand(index)}>
                {link.imageSrc && <img src={link.imageSrc} alt={link.text} className="product-thumbnail" />}
                <div className="link-text">{link.text}</div>
              </div>
            )}

            {expandedIndex === index && hasAdditionalLinks && (
              <div className="additional-links-lists">
                {link.additionalLinks.map((additionalLink, additionalIndex) => (
                  <div key={additionalIndex} className="additional-link-container">
                    {renderCompanyLogo(additionalLink.url)}
                    <a href={additionalLink.url} target="_blank" rel="noopener noreferrer">
                      {additionalLink.deliveryTime || 'Czas dostawy niedostępny'}
                    </a>
                    <div style={{ clear: "both" }}></div>
                  </div>
                ))}
              </div>
            )}
          </li>
        );
      })}
    </ul>
  );
};



const SingleLinkItem = ({ link }) => {
  const companyLogo = getCompanyFromUrl(link.url) ? companyLogos[getCompanyFromUrl(link.url)] : null;
  const getBackgroundColor = (url) => {
    const company = getCompanyFromUrl(url);
    switch (company) {
      case 'aliexpress':
        return '#E52D01';
      case 'amazon':
        return '#232f3e';
      case 'temu':
        return '#FC7701';
      default:
        return '#FFFFFF';
    }
  };
  return (
    <div className="additional-links-container" style={{ backgroundColor: getBackgroundColor(link.url), borderRadius: '8px' }}>
    <a href={link.url} target="_blank" rel="noopener noreferrer" className="additional-link-item">
      
        {companyLogo && <img src={companyLogo} alt="Company Logo" className="company-logo" />}
      
    </a>
    </div>
  );
};

const AdditionalLinksItem = ({ link }) => {
  const getBackgroundColor = (url) => {
    const company = getCompanyFromUrl(url);
    switch (company) {
      case 'aliexpress':
        return '#E52D01';
      case 'amazon':
        return '#232f3e';
      case 'temu':
        return '#FC7701';
      default:
        return '#FFFFFF';
    }
  };

  return (
    <div className="additional-links-container">
      {link.additionalLinks.map((additionalLink, index) => (
        <a 
          href={additionalLink.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          className="additional-link-item" 
          key={index}
          style={{ backgroundColor: getBackgroundColor(additionalLink.url) }} // Set background color based on the company
        >
          {getCompanyFromUrl(additionalLink.url) && (
            <img
              src={companyLogos[getCompanyFromUrl(additionalLink.url)]}
              alt={getCompanyFromUrl(additionalLink.url)}
              className="company-logo"
            />
          )}
          
        </a>
      ))}
    </div>
  );
};

const ProductItem = ({ link, PromoCode }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (!link || !link.url) {
    return null;
  }

  return (
    <div className="product-item" onClick={toggleExpand}>
      <img src={link.mainImageSrc || link.imageSrc} alt={link.text} className="product-thumbnail" />
      <div className="product-info">
        <span>{link.text}</span>
      </div>
      {isExpanded && (
        <div className="expanded-links">
          {link.additionalLinks && link.additionalLinks.length > 0 ? (
            <AdditionalLinksItem link={link} />
          ) : (
            <SingleLinkItem link={link} />
          )}
        </div>
      )}
    </div>
  );
};



const PromoCodesList = ({ promoCodesMini }) => {
  const getBackgroundColor = (company) => {
    switch (company) {
      case 'Amazon':
        return '#232f3e';
      case 'AliExpress':
        return '#E52D01';
      case 'TEMU':
        return '#FC7701';
      default:
        return '#FFFFFF';
    }
  };
  const getButtonColor = (company) => {
    switch (company) {
      case 'Amazon':
        return '#146eb4';
      case 'AliExpress':
        return '#FF552D';
      case 'TEMU':
        return '#FF902E';
      default:
        return '#FFFFFF';
    }
  };

  return (
    <div className="promo-codes-list">
      {promoCodesMini.map((promoCode, index) => (
        <PromoCode
          key={index}
          logoSrc={promoCode.logoSrc}
          promoCode={promoCode.promoCode}
          description={promoCode.description}
          backgroundColor={getBackgroundColor(promoCode.company)}
          buttonColor={getButtonColor(promoCode.company)}
          linkUrl={promoCode.linkUrl}
        />
      ))}
    </div>
  );
};

const PromoCode = ({ logoSrc, promoCode, description, backgroundColor, buttonColor, linkUrl }) => {
  const handleCopyClick = () => {
    navigator.clipboard.writeText(promoCode);
    alert(`Kod ${promoCode} został skopiowany do schowka!`);
  };

  return (
    <div className="promo-code-container" style={{ backgroundColor }}>
      <div className="promo-logo-container">
      <img src={logoSrc} alt="Company Logo" className="promo-company-logo" />
      </div>
      
      <div className="promo-details">
        <span className="promo-description">{description}</span>
        <div className="promo-code-section">
          <span className="promo-code">{promoCode}</span>
          <button className="copy-button" onClick={handleCopyClick} >
          <img className="copyImg" src={copyImg} alt="Opis obrazka" />
          </button>
        </div>
        <a href={linkUrl}>
        <button className="shop-button" style={{ backgroundColor: buttonColor }}>Przejdź do sklepu!</button>
        </a>
      </div>
    </div>
  );
};

function App() {
  const [clickedVideoSrc, setClickedVideoSrc] = useState(null);
  const [thumbnailSrc, setThumbnailSrc] = useState('');
  const [searchCode, setSearchCode] = useState('');
  const categoriesRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [fullScreenDiv, setFullScreenDiv] = useState(false);
  const [currentLinks, setCurrentLinks] = useState([]);
  const [showOrangeDiv, setShowOrangeDiv] = useState(false);
  const [productData, setProductData] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [showPromoCodes, setShowPromoCodes] = useState(false);
  const [loadedProducts, setLoadedProducts] = useState(6); // Początkowa liczba załadowanych produktów
  const loadMoreRef = useRef(null); // Ref do monitorowania widoczności elementu
  

  const promoCodes = [
    //{ logoSrc: amazonLogo, promoCode: 'AMZ123', description: '⭐Dla nowych uzytkownikow⭐ do 70% Taniej!', company: 'Amazon' },
    { logoSrc: aliexpressLogo, promoCode: 'AYP90075', description: '⭐Dla nowych uzytkownikow⭐ Oferty specjalne!', company: 'AliExpress', linkUrl: 'https://s.click.aliexpress.com/e/_DlK00qX' },
    { logoSrc: temuLogo, promoCode: 'apk84760', description: '⭐Dla nowych uzytkownikow⭐ 400zł kuponów, 30% taniej!💰', company: 'TEMU', linkUrl: 'https://temu.to/m/uaeno4fjqlkaby' },
  ];
  const promoCodesMini = [
    //{ logoSrc: amazonLogoMini, promoCode: 'AMZ123', description: '⭐Dla nowych uzytkownikow⭐ do 70% Taniej!', company: 'Amazon' },
    { logoSrc: aliexpressLogoMini, promoCode: 'AYP90075', description: '⭐Dla nowych uzytkownikow⭐ Do 70% Taniej!', company: 'AliExpress', linkUrl: 'https://s.click.aliexpress.com/e/_DlK00qX' },
    { logoSrc: temuLogoMini, promoCode: 'apk84760', description: '⭐Dla nowych uzytkownikow⭐ 400zł kuponów, 30% taniej!💰', company: 'TEMU', linkUrl: 'https://temu.to/m/uaeno4fjqlkaby' },
  ];
  
// Ładowanie filmu na podstawie URL po otwarciu strony
useEffect(() => {
  const urlParams = new URLSearchParams(window.location.search);
  const productId = urlParams.get('product');

  if (productId) {
    // Znajdź produkt na podstawie productId
    const product = productData.find(item => item.code === productId);
    
    if (product) {
      // Automatycznie otwórz filmik z odpowiednimi danymi
      handleThumbnailClick(product.videoSrc, product.thumbnailSrc, product.links, productId);
    }
  }
}, [productData]);

// Pobieranie danych o produktach
useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch('https://polskie-amalinki.pl/api/products');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const json = await response.json();
      setProductData(json);
      setFilteredProducts(json);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };
  
  fetchData();
}, []);

// Ładowanie kolejnych produktów przy scrollowaniu
//Nowy kod ładowania produktów
useEffect(() => {
  const handleIntersection = (entries) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setLoadedProducts((prevLoadedProducts) => prevLoadedProducts + 3); // Dodajemy 3 miniaturki na każde zescrollowanie
    }
  };

  const observer = new IntersectionObserver(handleIntersection, {
    root: null,
    rootMargin: '0px',
    threshold: 1.0,
  });

  if (loadMoreRef.current) {
    observer.observe(loadMoreRef.current);
  }

  return () => {
    if (loadMoreRef.current) {
      observer.unobserve(loadMoreRef.current);
    }
  };
}, []);


// Obsługa nawigacji wstecz/do przodu w przeglądarce i zarządzanie stanem full-screen-div
useEffect(() => {
  const handlePopState = (event) => {
    if (event.state && event.state.fullScreenDiv) {
      // Znajdź produkt na podstawie productId
      const product = productData.find(item => item.code === event.state.productId);
      if (product) {
        handleThumbnailClick(product.videoSrc, product.thumbnailSrc, product.links, event.state.productId);
      }
    } else {
      closeFullScreenDiv(); // Zamyka pełnoekranowy widok, jeśli cofniemy do stanu bez otwartego filmu
    }
  };

  window.addEventListener('popstate', handlePopState);

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };
}, [productData]);

// Obsługa zmiany stanu w zależności od otwartego full-screen-div lub searchCode
useEffect(() => {
  const handlePopState = (event) => {
    if (showOrangeDiv) {
        setShowOrangeDiv(false);
        // Sprawdź, czy FullScreenDiv jest otwarty, i jeśli tak, dodaj jego stan do historii
        if (fullScreenDiv) {
            window.history.replaceState({ fullScreenDiv: true }, ''); // Zastępuje obecny stan, aby uniknąć cofnięcia do poprzedniej strony
        }
    } else if (fullScreenDiv) {
        closeFullScreenDiv();
    } else if (searchCode) {
        setSearchCode('');
        setFilteredProducts(productData);
    } else {
        // Ensure we stay on the main content
        window.history.pushState(null, '', window.location.href);
    }
  };

  window.addEventListener('popstate', handlePopState);

  return () => {
    window.removeEventListener('popstate', handlePopState);
  };
}, [searchCode, fullScreenDiv, showOrangeDiv, productData]);

  
  
  const [selectedCategory, setSelectedCategory] = useState('');
  const [activeCategory, setActiveCategory] = useState(null); 
  // Add a function to handle category selection
  const handleCategoryClick = (category) => {
    if (activeCategory === category) {
      // If the clicked category is already active, reset to show all products
      setFilteredProducts(productData);
      setActiveCategory(null); // Reset active category
    } else {
      // Filter products based on category
      const filtered = productData.filter((product) => product.category === category);
      setFilteredProducts(filtered);
      setActiveCategory(category); // Set the active category
    }
  };

// Add a function to filter products by category
const filterProductsByCategory = (category) => {
  if (category === '') {
    setFilteredProducts(productData);
  } else {
    const filtered = productData.filter(product => product.category === category);
    setFilteredProducts(filtered);
  }
};
const handleTouchStart = (e) => {
  setIsDragging(true);
  setStartX(e.touches[0].pageX - categoriesRef.current.offsetLeft);
  setScrollLeft(categoriesRef.current.scrollLeft);
};

const handleTouchMove = (e) => {
  if (!isDragging) return;
  const x = e.touches[0].pageX - categoriesRef.current.offsetLeft;
  const walk = (x - startX) * 1.2; // Zmniejszamy czułość przesuwania
  categoriesRef.current.scrollLeft = scrollLeft - walk;
  e.preventDefault(); // Zapobiegamy domyślnemu przewijaniu na urządzeniu mobilnym
};

const handleTouchEnd = () => {
  setIsDragging(false);
};

  
  //przewijanie kategorii
  
  const handleThumbnailClick = (videoSrc, thumbnailSrc, links, productId) => {
    setFullScreenDiv(true);
    setCurrentLinks(links);
    setShowOrangeDiv(false);
    setClickedVideoSrc(videoSrc);
    setThumbnailSrc(thumbnailSrc);
  
    // Zmieniamy URL, dodając identyfikator produktu (productId)
    window.history.pushState({ fullScreenDiv: true, productId }, '', `?product=${productId}`);
    
    const videoElement = document.querySelector('video');
    if (videoElement) {
      videoElement.play();
    }
  };
  
  

  const closeFullScreenDiv = () => {
    setFullScreenDiv(false);
    setCurrentLinks([]);
    setShowOrangeDiv(false);
  
    // Zmieniamy URL na pierwotny (bez identyfikatora filmiku)
    window.history.pushState(null, '', window.location.pathname); 
  };
  

  
  

  const handleSearch = (searchValue) => {
    if (searchValue === 'DODAJ9') {
      setShowForm(true);
      return;
    }

    if (searchValue === '') {
      setFilteredProducts(productData);
    } else {
      const foundProducts = [];
      productData.forEach((product) => {
        if (product.code.includes(searchValue)) {
          product.links.forEach((link) => {
            if (link.additionalLinks && link.additionalLinks.length > 0) {
              foundProducts.push({ ...link, mainImageSrc: link.imageSrc });
            } else {
              foundProducts.push(link);
            }
          });
        } else {
          product.links.forEach((link) => {
            if (link.text.toLowerCase().includes(searchValue.toLowerCase())) {
              if (link.additionalLinks && link.additionalLinks.length > 0) {
                foundProducts.push({ ...link, mainImageSrc: link.imageSrc });
              } else {
                foundProducts.push(link);
              }
            }
          });
        }
      });
      setFilteredProducts(foundProducts);
    }
  };

  const handlePromoButtonClick = () => {
    setShowPromoCodes(!showPromoCodes);
  };

  return (
    <div className="App">
      <header className="App-header">
        <div className="logo"></div>
        <div className="socialsIcons">
          <a href="https://www.tiktok.com/@polskaamazonka" className="tt"><img className="socialIcon" src={tt} alt="Opis obrazka" /></a>
          <a href="https://www.facebook.com/PolskaAmazonka/" className="fb"><img className="socialIcon" src={fb} alt="Opis obrazka" /></a>
          <a href="https://www.instagram.com/polska.amazonka/" className="ig"><img className="socialIcon" src={ig} alt="Opis obrazka" /></a>
          <a href="https://www.youtube.com/@PolskaAmazonka" className="yt"><img className="socialIcon" src={yt} alt="Opis obrazka" /></a>
        </div>
      </header>

      <main className="main-content">
      <div className="search-bar">
  <input
    className="inpucik"
    type="text"
    placeholder="Wyszukaj kodem lub nazwą"
    value={searchCode}
    onChange={(e) => {
      setSearchCode(e.target.value);
      handleSearch(e.target.value);
    }}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        handleSearch(searchCode);
        e.target.blur(); // hide the keyboard on mobile
      }
    }}
  />
  <button
    className="search-icon"
    onClick={() => {
      handleSearch(searchCode);
      document.activeElement.blur(); // This hides the keyboard on mobile
    }}
  >
    <img src={searchIcon} alt="Search" />
  </button>
</div>

  <p>Witaj w wyszukiwarce najtańszych linków<br></br>🤠Polskiej Amazonki🏹</p>
  <div className='PromoButton' onClick={handlePromoButtonClick}>
  <img className="discountImg" src={discountImg} alt="Opis obrazka" />
  Odkryj Zniżki
  </div>
  {showPromoCodes && <PromoCodesList promoCodesMini={promoCodesMini} />}
  <hr class="hrstyle"></hr>
  <div className="categories" ref={categoriesRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
    <button className="category-button" onClick={() => handleCategoryClick('best')}>
      <img className="zdjeciePlaylisty" src={trophyImg} alt="Opis obrazka" />
      <span>Amazońska Topka</span>
    </button>
    <button className="category-button" onClick={() => handleCategoryClick('home')}>
      <img className="zdjeciePlaylisty" src={homeImg} alt="Opis obrazka" />
      <span>Dom Amazonki</span>
    </button>
    <button className="category-button" onClick={() => handleCategoryClick('finds')}>
      <img className="zdjeciePlaylisty" src={ideaImg} alt="Opis obrazka" />
      <span>Znaleziska Amazonki</span>
    </button>
    <button className="category-button" onClick={() => handleCategoryClick('seasons')}>
      <img className="zdjeciePlaylisty" src={seasonsImg} alt="Opis obrazka" />
      <span>4 pory roku</span>
    </button>
    <button className="category-button" onClick={() => handleCategoryClick('kids')}>
      <img className="zdjeciePlaylisty" src={toyImg} alt="Opis obrazka" />
      <span>Dzieci Amazonki</span>
    </button>
    <button className="category-button" onClick={() => handleCategoryClick('pets')}>
      <img className="zdjeciePlaylisty" src={petsImg} alt="Opis obrazka" />
      <span>Zwierzaki Amazonki</span>
    </button>
    
    
    <button className="category-button" onClick={() => handleCategoryClick('gifts')}>
      <img className="zdjeciePlaylisty" src={giftImg} alt="Opis obrazka" />
      <span>Prezenty</span>
    </button>
    <button className="category-button" onClick={() => handleCategoryClick('events')}>
      <img className="zdjeciePlaylisty" src={presentsImg} alt="Opis obrazka" />
      <span>święta Amazonki</span>
    </button>
    {/* Dodaj inne przyciski kategorii w ten sam sposób */}
  </div>

  {searchCode === '' && !fullScreenDiv && (
  <div className="product-grid">
    
    {filteredProducts.slice(0, loadedProducts).map((data, index) => (
      <PostWithBackground
      key={index}
      thumbnailSrc={data.thumbnailSrc}
      videoSrc={data.videoSrc}
      onClick={() => handleThumbnailClick(data.videoSrc, data.thumbnailSrc, data.links, data.code)} // Ensure data.code is passed here
    />
    
    ))}
    <div ref={loadMoreRef} style={{ height: '1px' }}></div>
  </div>
)}


{searchCode !== '' && !fullScreenDiv && (
  <div className="product-grid-item">
    {filteredProducts.slice(0, loadedProducts).map((link, index) => (
      <ProductItem key={index} link={link} />
    ))}
    <div ref={loadMoreRef} style={{ height: '1px' }}></div>
  </div>
)}



  {fullScreenDiv && (
    <FullScreenDiv
      onClose={closeFullScreenDiv}
      links={currentLinks}
      showOrangeDiv={showOrangeDiv}
      setShowOrangeDiv={setShowOrangeDiv}
      videoSrc={clickedVideoSrc}
      thumbnailSrc={thumbnailSrc}
    />
  )}

  {showForm && <Form onClose={() => setShowForm(false)} />}
</main>


      <footer className="App-footer"></footer>
    </div>
  );
}

export default App;
